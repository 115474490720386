.CalendarMonth_caption {
  padding-top: 0px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
}

.DayPicker_transitionContainer {
  height: 265px !important;
}

.DayPicker_transitionContainer__horizontal_2
  > div
  > div:nth-child(2)
  > div
  > div {
  text-align: left;
  padding-left: 1rem;
}

.DayPicker_transitionContainer__horizontal_2
  > div
  > div:nth-child(3)
  > div
  > div {
  text-align: right;
  padding-right: 1rem;
}

.DayPicker_weekHeader {
  top: 24px;
}

tr .CalendarDay__selected_span:first-of-type {
  border-radius: 50% 0 0 50% !important;
}

tr .CalendarDay__selected_span:last-of-type {
  border-radius: 0 50% 50% 0 !important;
}

tr .CalendarDay__selected_start,
tr .CalendarDay__selected_end {
  position: relative;
  background: transparent !important;
  z-index: 1;
  border: none !important;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50% !important;
    background: #157bfa;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: -2;
    background: #f7f9fa;
  }
}

tr .CalendarDay__selected_start {
  &::after {
    border-radius: 50% 0 0 50% !important;
  }
}

tr .CalendarDay__selected_end {
  &::after {
    border-radius: 0 50% 50% 0 !important;
  }
}

tr .CalendarDay__hovered_span:first-of-type {
  border-radius: 50% 0 0 50% !important;
}

tr .CalendarDay__hovered_span:last-of-type {
  border-radius: 0 50% 50% 0 !important;
}

.CalendarMonth_table {
  border-collapse: separate;
  border-spacing: 0 6px;
}

.CalendarDay {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  border: none !important;
  border-radius: 50%;

  &__selected {
    color: #fff;

    &:hover {
      background: #1066d2;
      border: 1px double #1066d2;
    }
  }

  &__selected_span {
    background: #f7f9fa !important;
    color: #2f323a;
    border-radius: 0 !important;
    border: none !important;
    &:hover {
      background: #c8cace;
      border: 1px double #e4e7e7;
      color: #2f323a;
    }
  }

  &__hovered_span,
  &__hovered_span:hover {
    background: #f7f9fa;
    color: #2f323a;
    border-radius: 0;
  }
}
